import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/escrow-section/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/escrow-section/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

/*
export function getEscrow (id) {
  return request({
    url: `/api-manager/escrow-section/${id}`,
    method: 'get'
  })
}
*/

export function createEscrow (data) {
  return request({
    url: '/api-manager/escrow-section',
    method: 'post',
    data
  })
}

export function storeEscrow (data) {
  return request({
    url: '/api-manager/app/escrow',
    method: 'post',
    data
  })
}

export function getEscrow () {
  return request({
    url: '/api-manager/app/escrow',
    method: 'get'
  })
}

export function updateEscrow (data, id) {
  return request({
    url: `/api-manager/escrow-section/${id}`,
    method: 'put',
    data
  })
}

export function deleteEscrow (id) {
  return request({
    url: `/api-manager/escrow-section/${id}`,
    method: 'delete'
  })
}
