<template>
  <div class="app-container">
  <el-row :gutter="4" type="flex" justify="center">
    <el-col :span="20">
      <el-card class="box-card">
        <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Bosque de Chapultepec</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
              <span>Primera Sección</span>
              <div style="margin-bottom: 4rem;"></div>
              <el-row justify="space-around">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Título">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.firstSection.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Descripción">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :rows="4" v-model="form.firstSection.description"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <span>Segunda Sección</span>
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="24" :lg="24" align="center">
                  <el-form-item label="Imagen">
                    <ImageUpload
                      :postImageTo="postImageTo"
                      :getImagesFrom="getGallery"
                      :setImage="form.secondSection.image"
                      @getImage="getSelectedImageSecondSection"
                    ></ImageUpload>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row justify="space-around" align="middle">
                <el-col :sm="24" :md="24" :lg="24">
                  <el-form-item label="Título">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.secondSection.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Descripción">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :rows="4" v-model="form.secondSection.description"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <span>Tercera Sección</span>
              <el-row justify="center"  align="middle" :gutter="20">
                <el-col :sm="24" :md="24" :lg="24" >
                  <el-form-item label="Título Sección Izquierda" label-position="top">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.thirdSection.left.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Descripción Sección Izquierda">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :rows="4" v-model="form.thirdSection.left.description"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Título Sección Centro" label-position="top">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.thirdSection.center.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Descripción Sección Centro">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :rows="4" v-model="form.thirdSection.center.description"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Título Sección Derecha" label-position="top">
                    <ValidationProvider name="Título" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.thirdSection.right.title"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Descripción Sección Derecha">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <el-input type="textarea" :rows="4" v-model="form.thirdSection.right.description"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row  justify="center" >
                <el-col align="middle">
                  <el-form-item label="Importancia Ambiental" style="width: 70% !important;">
                    <ValidationProvider name="Descripción Mision" rules="required" v-slot="{ errors }">
                      <CKEditor :setContent="form.mission" :key="form.mission" @getContent="getUpdatedContentMission"></CKEditor>
                      <span style="color: #F56C6C;">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row  justify="center" >
                <el-col align="middle">
                  <el-form-item label="Importancia Social" style="width: 70% !important;">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <CKEditor :setContent="form.vision" :key="form.vision" @getContent="getUpdatedContentVision"></CKEditor>
                      <span style="color: #F56C6C;">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row  justify="center" >
                <el-col align="middle">
                  <el-form-item label="Importancia Cultural" style="width: 70% !important;">
                    <ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
                      <CKEditor :setContent="form.values" :key="form.values" @getContent="getUpdatedContentValues"></CKEditor>
                      <span style="color: #F56C6C;">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row  justify="center" >
                <el-col align="middle">
                  <el-form-item label="Fideicomiso Pro Bosque de Chapultepec" style="width: 70% !important;">
                    <ValidationProvider name="Descripción Transparencia" rules="required" v-slot="{ errors }">
                      <CKEditor :setContent="form.transparency" :key="form.transparency" @getContent="getUpdatedContentTransparency"></CKEditor>
                      <span style="color: #F56C6C;">{{ errors[0] }}</span>
                      <span v-if="errors[0]!=''"><br></span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCreate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Agregar
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import { uploadImageFile } from '@/api/image.js'
import { search } from '@/api/gallery.js'
import ImageUpload from '@/components/imageUpload/ImageUpload.vue'
import { storeEscrow, getEscrow } from '@/api/escrowSection.js'
import CKEditor from '@/components/CKEditor/CKEditor.vue'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi

export default {
  name: 'createProject',
  components: {
    ImageUpload,
    CKEditor
  },
  data () {
    return {
      host: urlServer,
      form: {
        mission: '',
        vision: '',
        values: '',
        transparency: '',
        firstSection: {
          title: '',
          image: null,
          description: ''
        },
        secondSection: {
          title: '',
          image: null,
          description: ''
        },
        thirdSection: {
          left: {
            title: '',
            description: ''
          },
          center: {
            title: '',
            description: ''
          },
          right: {
            title: '',
            description: ''
          }
        }
      },
      contentEditorMission: '',
      contentEditorVision: '',
      contentEditorValues: '',
      contentEditorTransparency: '',
      loadingImage: false,
      imageSelected: false
    }
  },
  mounted () {
    this.handlerFetchEscrow()
  },
  methods: {
    async postImageTo (param) {
      return await uploadImageFile(param)
    },
    async getGallery (param) {
      return await search(param)
    },
    getSelectedImageFirstSection (imageUploaded) {
      this.form.firstSection.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.firstSection.image)
    },
    getSelectedImageSecondSection (imageUploaded) {
      this.form.secondSection.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.secondSection.image)
    },
    getSelectedImageThirdSection (imageUploaded) {
      this.form.thirdSection.image = imageUploaded
      console.log('imagen cargada en formulario ' + this.form.thirdSection.image)
    },
    handlerCancel () {
      this.$router.push('/escrow-section')
    },
    async handlerFetchEscrow () {
      // if (true /*this.form.image !== '' && this.form.image !== null*/) {
      try {
        const response = await getEscrow()
        console.log(this.form)
        if (response.success) {
          var data = await response.data
          if (data.length !== 0) {
            this.form = data
            this.contentEditorMission = this.form.mission
            this.contentEditorVision = this.form.vision
            this.contentEditorValues = this.form.values
            this.contentEditorTransparency = this.form.transparency
          }
        } else {
          console.log(response)
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.messages) {
          err.response.data.messages.forEach(message => {
            this.$message.error(`${message}`)
          })
        } else {
          console.log(err)
        }
      }
      // } else {
      // this.$message.error('Asegurate que no falte ningun archivo o dato')
      // }
    },
    async handlerCreate () {
      this.form.mission = this.contentEditorMission
      this.form.vision = this.contentEditorVision
      this.form.values = this.contentEditorValues
      this.form.transparency = this.contentEditorTransparency
      try {
        const response = await storeEscrow(this.form)
        if (response.success) {
          console.log(response)
          this.$message({
            showClose: true,
            message: '¡Se cargó correctamente!',
            type: 'success'
          })
          // this.$router.push('/escrow-section')
        } else {
          console.log(response)
        }
      } catch (err) {
        err.response.data.messages.forEach(message => {
          this.$message.error(`${message}`)
        })
      }
      // } else {
      // this.$message.error('Asegurate que no falte ningun archivo o dato')
      // }
    },
    getUpdatedContentMission (content) {
      this.contentEditorMission = content
    },
    getUpdatedContentVision (content) {
      this.contentEditorVision = content
    },
    getUpdatedContentValues (content) {
      this.contentEditorValues = content
    },
    getUpdatedContentTransparency (content) {
      this.contentEditorTransparency = content
    }
  }
}
</script>

<style scoped>
.label-form-validate-wrong {
  color: #F56C6C;
}

.size-form-item > .el-form-item {
  margin: 0;
}
.size-form-item > .el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
.my-autocomplete {
  width: 500px;
}
</style>
